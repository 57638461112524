
import { Component, Vue } from "vue-property-decorator";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import ProductDetailsModule from "@/store/modules/productdetails.module";
import { ProductsCard } from "@/models/entities/products-card.interface";
import { productPhoto } from "@/services/api/productPhoto.service";
import { CartProduct } from "@/models/entities/cart-product.interface";
import AuthModule from "@/store/modules/auth.module";
import { CLIENT, PUBLIC } from "@/helpers/roles";
import { orders } from "@/services/api/orders.service";
import CartModule from "@/store/modules/cart.module";
import OverlayModule from "@/store/modules/overlay.module";
import { productPriceBooks } from "@/services/api/productPriceBooks.service";
import { Tag } from "@/models/entities/tag.interface";
import { products } from "@/services/api/products.service";
import SearchModule from "@/store/modules/search.module";
import SnackbarModule from "@/store/modules/snackbar.module";
const productDetailsModule = getModule(ProductDetailsModule, store);
const authModule = getModule(AuthModule, store);
const cartModule = getModule(CartModule, store);
const overlayModule = getModule(OverlayModule, store);
const searchModule = getModule(SearchModule, store);
const snackbarModule = getModule(SnackbarModule, store);

@Component
export default class ProductDetails extends Vue {
  private months = [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre",
  ];
  private days = [
    "Lunedì",
    "Martedì",
    "Mercoledì",
    "Giovedì",
    "Venerdì",
    "Sabato",
    "Domenica",
  ];
  private photoProgress = false;
  private quantity = 1;
  private selectedMeasureUnit = null;
  private prices = {
    price: null,
    priceInPromo: null,
    isInPromo: false,
    total: 0,
  };
  private img = null;
  private tags: Tag[] = [];
  private counter = 0;
  private isDescrExpanded = false;

  private get products(): ProductsCard[] {
    this.counter++;
    return searchModule.productsCard.map((el) => {
      return { ...el };
    });
  }

  private get product(): ProductsCard {
    return productDetailsModule.product;
  }

  private get productionDays(): string {
    let productionDays: string[] = [];
    if (this.product && this.product.productionDays)
      this.product.productionDays
        .split("|")
        .forEach((s: string) => productionDays.push(this.days[Number(s)]));
    return productionDays.join(", ");
  }

  private get productionMonths(): string {
    let productionMonths: string[] = [];
    if (this.product && this.product.productionMonths) {
      this.product.productionMonths
        .split("|")
        .forEach((s: string) => productionMonths.push(this.months[Number(s)]));
    }
    return productionMonths.join(", ");
  }

  private get cuttedDescription(): string {
    if (
      this.product.productDescription &&
      this.product.productDescription.length > 512 &&
      !this.isDescrExpanded
    ) {
      return `${this.product.productDescription.slice(0, 512)}...`;
    }
    return this.product.productDescription;
  }

  private get mobile(): boolean {
    return this.$vuetify.breakpoint.xsOnly;
  }

  private async created(): Promise<void> {
    if (!this.product) this.$router.push({ name: "home" });
    await this.getTags();
    this.setDefaultValue();
  }

  private mounted(): void {
    this.$vuetify.goTo("#title");
  }

  private async getTags(): Promise<void> {
    overlayModule.showOverlay();
    const t = await products.getTagsByProductID(this.product.id);
    this.tags = t.data;
  }

  private async onFavorite(): Promise<void> {
    overlayModule.showOverlay();
    await productPriceBooks.updateProductPriceBookIsFavorite(
      this.product.productPriceBookID
    );
    this.product.isFavorite = !this.product.isFavorite;
    this.toggleFavorite(`product_${this.product.id}`);
  }

  private toggleFavorite(ref: string): void {
    if (this.$refs[ref])
      (
        this.$refs[ref][0] as Vue & { changeFavorite: () => void }
      ).changeFavorite();
  }

  private onCardFavorite(product: ProductsCard): void {
    if (product.id === this.product.id)
      this.product.isFavorite = !this.product.isFavorite;
  }

  private setDefaultValue(): void {
    this.$set(this.prices, "price", this.product.prices[0].price);
    this.$set(this.prices, "priceInPromo", this.product.prices[0].priceInPromo);
    this.$set(this.prices, "isInPromo", this.product.prices[0].isInPromo);
    if (this.prices.isInPromo) {
      this.$set(this.prices, "total", this.prices.priceInPromo * this.quantity);
    } else {
      this.$set(this.prices, "total", this.prices.price * this.quantity);
    }
    this.selectedMeasureUnit = this.product.prices[0].measureUnitTypeID;
    this.photo();
  }

  private onChangeMeasureUnit(): void {
    const p = this.product.prices.find(
      (price) => price.measureUnitTypeID == this.selectedMeasureUnit
    );
    if (p) {
      this.$set(this.prices, "price", p.price);
      this.$set(this.prices, "priceInPromo", p.priceInPromo);
      this.$set(this.prices, "isInPromo", p.isInPromo);
      if (!this.quantity) this.quantity = 1;
      if (this.prices.isInPromo) {
        this.$set(
          this.prices,
          "total",
          this.prices.priceInPromo * this.quantity
        );
      } else {
        this.$set(this.prices, "total", this.prices.price * this.quantity);
      }
      this.photo();
    }
  }

  private async photo(): Promise<void> {
    this.photoProgress = true;
    let photo = null;
    try {
      photo = (
        await productPhoto.getPhotoByMeasureUnitTypeID(
          this.product.productPriceBookID,
          this.selectedMeasureUnit
        )
      ).data;
    } catch (error) {
      console.error;
    }
    if (photo.byteLength == 0) {
      photo = (await productPhoto.getDefaultPhotoByProductId(this.product.id))
        .data;
    }
    let p = new File([photo], "photo");
    var binaryData = [];
    if (p) {
      binaryData.push(p);
      this.img = window.URL.createObjectURL(
        new Blob(binaryData, { type: "image/png" })
      );
    }
    this.photoProgress = false;
  }

  private async onCartClick(): Promise<void> {
    const product = {} as CartProduct;
    this.$set(product, "productPriceBookID", this.product.productPriceBookID);
    this.$set(product, "quantity", this.quantity);
    this.$set(product, "measureUnitTypeID", this.selectedMeasureUnit);
    if (authModule.role === PUBLIC) {
      this.$router.push({
        name: "login",
        params: { product: JSON.stringify(product) },
      });
    } else if (authModule.role === CLIENT) {
      await orders.addOrder(product);
      cartModule.add(product);
      snackbarModule.showSnackbar({
        message: "Prodotto aggiunto al carrello",
        type: "success",
      });
      setTimeout(() => snackbarModule.hideSnackbar(), 1500);
    }
    this.quantity = 1;
  }

  private productChange(id: number) {
    let productFound = this.products.find(
      (product: ProductsCard) => product.id === id
    );
    if (productFound) {
      productDetailsModule.changeProduct(productFound);
      this.setDefaultValue();
      this.$vuetify.goTo("#title");
    } else {
      snackbarModule.showSnackbar({
        message: "Prodotto non trovato, prova a ricaricare la pagina",
        type: "error",
      });
    }
  }

  private toggleDescription(): void {
    this.isDescrExpanded = !this.isDescrExpanded;
  }
}
